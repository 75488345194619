import { Button, Modal } from "antd"
import { Dispatch, forwardRef, SetStateAction, useCallback, useImperativeHandle, useState } from "react"

type Props = {
  handleReviewTransaction: (transaction: any) => void
  isReviewingTransaction: boolean
}

export type TConfirmReviewTransactionRef = {
  handleChangeConfirmVisible: (visible: boolean) => void
  setSelectedTransaction: Dispatch<SetStateAction<any>>
}

export const ConfirmReviewTransaction = forwardRef<TConfirmReviewTransactionRef, Props>(({ handleReviewTransaction, isReviewingTransaction }, ref) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState<any>()

  const handleChangeConfirmVisible = useCallback((visible: boolean) => {
    setShowConfirm(visible)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      handleChangeConfirmVisible,
      setSelectedTransaction
    }
  }, [handleChangeConfirmVisible])

  return <>
    <Modal
      visible={showConfirm}
      title={'Xác nhận duyệt giao dịch'}
      centered
      className={'[&.ant-modal .ant-modal-content >.ant-modal-header]:p-4'}
      footer={null}
      onCancel={() => {
        setSelectedTransaction(undefined)
        handleChangeConfirmVisible(false)
      }}
      destroyOnClose
    >
      <div>
        Bạn có chắc chắn muốn duyệt giao dịch <span className="font-medium">{selectedTransaction?.code}</span>?
      </div>
      <div className={'flex justify-content-flex-end mt-6'}>
        <Button type={'ghost'} onClick={() => handleChangeConfirmVisible(false)} disabled={isReviewingTransaction}>
          <i className="fa-solid fa-xmark mg-r-6" />Hủy bỏ
        </Button>
        <Button type={'primary'}
          className={'mg-l-8'}
          onClick={() => {
            if (selectedTransaction) {
              handleReviewTransaction(selectedTransaction)
              setSelectedTransaction(undefined)
              handleChangeConfirmVisible(false)
            }
          }}
          loading={isReviewingTransaction}
        // disabled={dateRange.length < 1 || error.length > 1}
        >
          <i className="fa-regular fa-check mg-r-6" />Duyệt
        </Button>
      </div>
    </Modal>
  </>

})