import { Alert, Button, Checkbox, Menu, Popover, Tooltip, Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { ColumnsType } from 'antd/lib/table'
import Paragraph from 'antd/lib/typography/Paragraph'
import { AxiosError } from 'axios'
import lodash, { findIndex, get, indexOf } from 'lodash'
import moment from 'moment'
import { ExpandableConfig } from 'rc-table/lib/interface'
import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import PaymentReasonApi from '../../../Api/PaymentReasonApi'
import SubTransaction from '../../../Api/SubTransaction'
import TransactionApi from '../../../Api/TransactionApi'
import CommonTable from '../../../Component/CommonTable'
import TransactionListByUser from '../../../Component/Drawer/TransactionListByUser'
import { LogModal } from '../../../Component/LogModal'
import { LogModalRef } from '../../../Component/LogModal/type'
import { ModalCommon } from '../../../Component/ModalCommon'
import { NotificationCommon } from '../../../Component/Notification'
import SubTransactionViewList from '../../../Component/SubTransactionViewList'
import { AppContext } from '../../../Context/AppContext'
import AccountDomain from '../../../Domain/AccountDomain'
import ProjectDomain from '../../../Domain/ProjectDomain'
import TransactionsDomain, {
    TransactionInterface,
    TransactionReviewStatusEnum,
    TransactionStatusEnum
} from '../../../Domain/TransactionDomain'
import { LogResrouceType } from '../../../Interface/LogInterface'
import { ACCOUNT_PERMISSIONS, REVIEW_STATUSES_CODE, TRANSACTION_TYPES } from '../../../Util/Constants'
import SecurityService from '../../../Util/SecurityService'
import Utils from '../../../Util/Utils'
import { ConfirmReviewTransaction, TConfirmReviewTransactionRef } from './components/ConfirmReviewTransaction'
import { ListItem } from './components/LogListItem'
import TransactionCustomRow from "./components/TransactionCustomRow"
import {
    getFailedReason,
    renderAddSub,
    renderClone,
    renderEdit,
    renderEditNote,
    renderRejectPayment,
    renderRetry
} from './helpers'

interface Props {
    projects: Array<ProjectDomain>
    loading?: boolean
    items: Array<TransactionsDomain>
    total: number
    pageSize: number
    currentPage: number
    keyColumnsToShow: CheckboxValueType[]
    reloadTransaction: () => void
    onChangePage: (page: number, pageSize?: number) => void
    onRetrySynchronize: (transactionCode: string) => void
    handleCurrentTransactionEdit: (record: TransactionsDomain) => void
    handleModalEditTransactionVisibleChange: (visible: boolean) => void
    handleModalEditTransactionNoteVisibleChange: (visible: boolean) => void
    handleReviewTransaction: (row: TransactionsDomain) => void
    reviewingTrasaction: boolean
    handleModalCreateSubTransactionVisibleChange: (visible: boolean) => void
    handleMarkRechargeTransaction: (row: TransactionsDomain) => void
    fetchTransactions: (filter: any) => void
    cloneVoucher: (row: { paymentVoucher: string; receiptVoucher: string }) => void
}

interface State {
    account: AccountDomain
    showTransactionsByUser: boolean
    selectedRow: TransactionsDomain | undefined
    paymentReasons: any[]
    allowCreateSubs: boolean
    defaultExpandedRow: string[]
    focusRows: any[]
    itemHasSubTransaction: any[]
}

const errorMapping: any = {
    'transaction_settled': 'Không thể hủy giao dịch do quỹ đã chốt sổ',
    'transaction_reviewed': 'Giao dịch đã được duyệt',
    'transaction_rejected': 'Giao dịch đã bị hủy. Vui lòng kiểm tra lại.'
}

class DataList extends Component<Props, State> {
    static contextType = AppContext
    logRef = createRef<LogModalRef>()
    confirmReviewTransactionRef = createRef<TConfirmReviewTransactionRef>();

    state: State = {
        account: Utils.getAppContext(this).state.account,
        showTransactionsByUser: false,
        selectedRow: undefined,
        paymentReasons: [],
        allowCreateSubs: true,
        defaultExpandedRow: [],
        focusRows: [],
        itemHasSubTransaction: [],
    }

    componentDidMount() {
        this.getPaymentReasons()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        const searchParams = Utils.getQueryFromLocation(window.location);
        if (this.props.items !== prevProps.items) {
            let { itemHasSubTransaction } = this.state;
            let items: any[] = this.props.items;
            if (searchParams.query || searchParams.paymentReason) {
                let temp: any = itemHasSubTransaction;
                if (items) {
                    items.forEach((i: any) => {
                        temp.push(i['code']);
                        this.setState({ itemHasSubTransaction: temp })
                    })
                }
            }
            if (!searchParams || lodash.isEmpty(searchParams)) {
                this.setState({ itemHasSubTransaction: [] })
            }
        }
    }

    transactionHasError = (record: any) => {
        if ((record.status !== TransactionStatusEnum.REJECTED && get(record, 'txLog.status') === 'FAIL')) return true
    }

    renderTransactionError = (record: any) => {
        if (record.status === TransactionStatusEnum.REJECTED) return null

        const isShowError = get(record, 'txLog.status') === 'FAIL'
        const lastReason: string = get(record, 'txLog.reason') || get(record, 'txLog.requestResponse.message') || ''

        if (!lastReason || !isShowError) {
            return
        }

        const { reason, guide } = getFailedReason(lastReason || '', record)

        return (
            <div className={'transaction-error'}>
                <Alert
                    message={<><span className=""><i
                        className="fa-solid fa-circle-exclamation"></i></span> Lỗi: {reason}</>}
                    description={guide && <><span className="font-medium pd-bt-4">Hướng dẫn xử lý: </span>{guide}.</>}
                    type="error"
                    closable={false}
                // onClose={(e) => {
                //     e.stopPropagation()
                // }}
                />
            </div>
        )
    }

    getConfigColumns = () => {
        const { account } = this.state
        const { projects, keyColumnsToShow, cloneVoucher } = this.props

        let columns: ColumnsType<any> = [
            {
                title: 'Mã',
                key: 'code',
                render: (record: any) => {
                    const code = lodash.get(record, 'receiptVoucher') || lodash.get(record, 'paymentVoucher')
                    const ref = lodash.get(record, 'ref')
                    const referenceTx = lodash.get(record, 'referenceTx')
                    const voucherLink = lodash.get(record, 'receiptVoucher') ? `/receipt-vouchers/${code}` : `/payment-vouchers/${code}`
                    const deliveryNote = lodash.get(record, 'deliveryNote', '')

                    return (<div>
                        <div style={{ wordBreak: 'break-all' }}>
                            <div>
                                <Tooltip title={'Mã giao dịch'}>
                                    <Paragraph
                                        className={'italic transaction-code d-inline-block white-space-pre'}
                                        copyable={{
                                            icon: (
                                                <i
                                                    className="fa-regular fa-copy"
                                                    style={{ color: '#7D7E7E', fontSize: 12 }}
                                                />
                                            ),
                                            text: record.code,
                                        }}>
                                        {record.code}
                                    </Paragraph>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={'Mã phiếu'}>
                                    {code ? (
                                        <Paragraph
                                            className="d-inline-block white-space-pre"
                                            copyable={{
                                                icon: (
                                                    <i
                                                        className="fa-regular fa-copy pd-bt-2"
                                                        style={{ color: '#7D7E7E', fontSize: 12 }}
                                                    />
                                                ),
                                                text: code,
                                            }}>
                                            <Link
                                                title={'Mã phiếu'}
                                                to={voucherLink}>
                                                {code}
                                            </Link>
                                        </Paragraph>
                                    ) : (
                                        '--'
                                    )}
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={'Mã bút toán'}>
                                    {ref ? (
                                        <Paragraph
                                            className="d-inline-block white-space-pre"
                                            copyable={{
                                                icon: (
                                                    <i
                                                        className="fa-regular fa-copy pd-bt-2"
                                                        style={{ color: '#7D7E7E', fontSize: 12 }}
                                                    />
                                                ),
                                                text: ref,
                                            }}>
                                            {ref}
                                        </Paragraph>
                                    ) : (
                                        '---'
                                    )}
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={'Mã tham chiếu'}>
                                    {referenceTx ? (
                                        <Paragraph
                                            className="d-inline-block"
                                            copyable={{
                                                icon: (
                                                    <i
                                                        className="fa-regular fa-copy pd-bt-2"
                                                        style={{ color: '#7D7E7E', fontSize: 12 }}
                                                    />
                                                ),
                                                text: referenceTx,
                                            }}>
                                            {referenceTx}
                                        </Paragraph>
                                    ) : (
                                        '---'
                                    )}
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Mã phiếu xuất">
                                    {deliveryNote ? (
                                        <Paragraph
                                            className="d-inline-block whitespace-nowrap"
                                            copyable={{
                                                icon: (
                                                    <i
                                                        className="fa-regular fa-copy pd-bt-2"
                                                        style={{ color: '#7D7E7E', fontSize: 12 }}
                                                    />
                                                ),
                                                text: deliveryNote,
                                            }}>
                                            {deliveryNote}
                                        </Paragraph>
                                    ) : (
                                        '---'
                                    )}
                                </Tooltip>

                            </div>
                        </div>
                        {/*{this.renderTransactionError(record)}*/}
                    </div>)
                },
            },
            {
                title: 'Hệ thống',
                key: 'system',
                align: 'center',
                render: (record: any) => {
                    const { projects } = this.props
                    const projectCode = lodash.get(record, 'participantRef.project') || record.project
                    const project: ProjectDomain | undefined = projects.find((item: any) => item.code === projectCode)
                    if (project) {
                        const txtClass = project.status === 'INACTIVE' ? 'text-secondary-color' : ''
                        return (<div>
                            <div style={{ minWidth: 150 - keyColumnsToShow.length * 6 }}>
                                <div className={txtClass}>({project.code})</div>
                                <div className={txtClass}>{project.name}</div>
                            </div>
                        </div>)
                    }
                    return '---'
                },
            },
            {
                title: 'Thời gian giao dịch',
                key: 'timestamp',
                dataIndex: 'timestamp',
                align: 'right',
                render: (d: any) => (<div>
                    <span className={''}>{d ? moment(d).format('DD/MM/YYYY HH:mm:ss') : '--'}</span>
                </div>)
            },
            {
                title: 'Loại GD',
                key: 'transactionType',
                dataIndex: 'transactionType',
                align: 'left',
                render: (d: any) => (<div>
                    <span className={''}>{d ? TRANSACTION_TYPES[d] : TRANSACTION_TYPES['CUSTOMER']}</span>
                    {/* <span className={''}>{d ? d : 'CUSTOMER'}</span> */}
                </div>)
            },
            {
                title: 'Mã đơn',
                key: 'm1Orders',
                dataIndex: 'm1Orders',
                align: 'left',
                render: (d: any) => (<div className='flex flex-col gap-2'>
                    {
                        d && Array.isArray(d) && d.length > 0 && d.map((code: string) => {
                            return <span className={''}>{code}</span>
                        })
                    }
                </div>)
            },
            {
                title: 'Người nhận/nộp',
                key: 'participant',
                dataIndex: 'participant',
                // width: 50,
                render: (d: any, record: any) => {
                    const orderCode = lodash.get(record, 'orderCode', '')
                    const renderCustomerUserName = () => {
                        if (lodash.has(record, 'participantRef')) {
                            const cusUrl = Utils.getCustomerProjectUrl(projects, record.participantRef?.project, record.participantRef?.username)
                            return (
                                <div>
                                    <a
                                        href={cusUrl}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        className="nowrap">
                                        {lodash.get(record, 'participantRef.username')}
                                    </a>
                                </div>
                            )
                        }
                        return lodash.get(record, 'participantRef.username')
                    }

                    return (
                        <div
                            className={'flex width-100pc'}
                            style={{ minWidth: 250 - keyColumnsToShow.length * 6 }}>
                            {
                                record.transactionType !== 'ORDER' ? <>
                                    {!record.participantRef ? (
                                        <span className={'d-inline-block participant break-word whitespace-nowrap'}>{d || '---'}</span>
                                    ) : (
                                        <Tooltip
                                            title={
                                                lodash.get(record, 'participantRef.fullname')
                                                    ? `${lodash.get(record, 'participantRef.fullname')} (${lodash.get(record, 'participantRef.project')})`
                                                    : d
                                            }
                                            placement={'top'}>
                                            <span
                                                className={`d-inline-block participant break-word whitespace-nowrap`}>{renderCustomerUserName()}</span>
                                        </Tooltip>
                                    )}
                                </>
                                    : <>
                                        {
                                            ((!record.m1Orders || record.m1Orders.length === 0) && !record?.participantRef) ?
                                                <div>
                                                    <Tooltip title="Mã thanh toán">
                                                        {orderCode ? (
                                                            <Paragraph
                                                                className="d-inline-block"
                                                                copyable={{
                                                                    icon: (
                                                                        <i
                                                                            className="fa-regular fa-copy pd-bt-2"
                                                                            style={{ color: '#7D7E7E', fontSize: 12 }}
                                                                        />
                                                                    ),
                                                                    text: orderCode,
                                                                }}>
                                                                {orderCode}
                                                            </Paragraph>
                                                        ) : (record.type === 'DEPOSIT_BY_PAYMENT' && record.reviewStatus === "PENDING") ? (
                                                            <>
                                                                <Tooltip title={`Hệ thống: ${projects?.find((p) => p?.code === record?.project)?.name}`}>
                                                                    {record.orderCode}
                                                                </Tooltip>
                                                            </>
                                                        ) : ('---')}
                                                    </Tooltip>
                                                </div>
                                                : <div>
                                                    {(lodash.has(record, 'participantRef')) ? <span>
                                                        {renderCustomerUserName()}
                                                    </span> : orderCode
                                                    }
                                                </div>
                                        }
                                    </>
                            }
                            <div>
                            </div>

                            <div className={'mg-l-4'}>
                                <Tooltip title={'Lịch sử giao dịch khách hàng'}>
                                    {record.participant || record.participantRef ? (
                                        <span
                                            onClick={() => this.openDrawerList(record)}
                                            className={''}
                                            style={{ color: '#27ae61' }}>
                                            {' '}
                                            |<i className="fa-solid fa-memo-circle-info pd-l-8" />
                                        </span>
                                    ) : null}
                                </Tooltip>
                            </div>

                        </div>
                    )
                },
            },
            {
                title: 'Vai trò',
                key: 'roles',
                align: 'center',
                render: (row: any) => {
                    return (
                        <Tooltip
                            title={
                                <div className={'flex-col'}>
                                    <div>
                                        Người tạo GD: <span
                                            className={'bold'}>{row.preparedBy ? lodash.get(row, 'preparedBy') : '---'}</span>
                                    </div>
                                    <div>
                                        Người xác nhận GD:{' '}
                                        <span
                                            className={'bold'}>{row.approvedBy ? lodash.get(row, 'approvedBy') : '---'}</span>
                                    </div>
                                    <div>
                                        Người duyệt: <span
                                            className={'bold'}>{row.reviewedBy ? lodash.get(row, 'reviewedBy') : '---'}</span>
                                    </div>
                                </div>
                            }>
                            <span>
                                <i className="fa-regular fa-users text-secondary-color-2" />
                            </span>
                        </Tooltip>
                    )
                },
            },
            {
                title: 'Nội dung GD',
                key: 'memo',
                dataIndex: 'memo',
                render: (memo: any, row: any) => {
                    let account = Utils.getAppContext(this).state.account
                    return (
                        <div
                            style={{ maxWidth: 500 - keyColumnsToShow.length * 12 }}
                        >
                            <div>
                                <div className={'mg-r-2'}>
                                    Quỹ: <span className={'bold'}>{account.name}</span>
                                </div>
                                <span>
                                    {account.type === 'BANK' ? (
                                        <span>
                                            <div className={'mg-r-2'}>
                                                NH: <span className={'bold'}>{lodash.get(account.bank, 'name')}</span>
                                            </div>
                                            <div className={'mg-r-2'}>
                                                STK: <span
                                                    className={'bold'}>{lodash.get(account, 'accountNumber')}</span>
                                            </div>
                                        </span>
                                    ) : (
                                        <span className={'mg-r-2 bold'}>. Tài khoản tiền mặt</span>
                                    )}
                                </span>
                            </div>
                            <div>{memo}</div>

                            {row.remark && (
                                <div className={''}>
                                    <Typography.Text
                                        className={'transaction-remark font-size-12'}
                                        ellipsis={{ tooltip: row.remark }}>
                                        <i className="fa-regular fa-circle-exclamation" /> {row.remark}
                                    </Typography.Text>
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                title: 'Trạng thái',
                key: 'reviewStatus',
                dataIndex: 'reviewStatus',
                align: 'center',
                render: (reviewStatus: string, row: any) => {
                    if (row.status === TransactionStatusEnum.REJECTED) return <span
                        className={`status-REJECTED`}>Đã hủy</span>

                    let temp = this.state.focusRows
                    let status = REVIEW_STATUSES_CODE.find((x) => x.code === reviewStatus)
                    return (
                        <div>
                            <span className={`status-${status?.code}`}>{status?.name}</span>
                            {reviewStatus === 'PENDING' && SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_REVIEW) && (
                                <div
                                    className={'underline text-blue font-size-12 pd-t-4'}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        temp.splice(findIndex(temp, row.code), 1, row.code)
                                        this.setState({ focusRows: temp })
                                        // handleReviewTransaction(row)
                                        this.confirmReviewTransactionRef.current?.setSelectedTransaction(row)
                                        this.confirmReviewTransactionRef.current?.handleChangeConfirmVisible(true)
                                    }}>
                                    Duyệt ngay
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                title: 'Số tiền',
                key: 'amount',
                align: 'right',
                render: (row: any) => {
                    const ACCOUNT = Utils.getAppContext(this).state.account
                    const CURRENCY = lodash.get(ACCOUNT, 'currency.code')
                    return (
                        <span className={`bold fsz-16px ${row.amount < 0 ? 'negative-money' : 'positive-money'}`}>
                            {Utils.currencyFormatByCurrency(row.amount, CURRENCY)}
                        </span>
                    )
                },
            },
            {
                title: 'Số dư',
                key: 'balance',
                align: 'right',
                render: (row: any) => {
                    if (row.status === TransactionStatusEnum.REJECTED) return '---'
                    const ACCOUNT = Utils.getAppContext(this).state.account
                    const CURRENCY = lodash.get(ACCOUNT, 'currency.code')

                    return (
                        <span className={`bold fsz-16px ${row.balance < 0 ? 'negative-money' : 'positive-money'}`}>
                            {Utils.currencyFormatByCurrency(row.balance, CURRENCY)}
                        </span>
                    )
                },
            },
        ]

        if (account.type === 'BANK') {
            columns.push({
                title: 'Số dư từ SMS',
                key: 'refBalance',
                align: 'right',
                render: (row: any) => {
                    const ACCOUNT = Utils.getAppContext(this).state.account
                    const CURRENCY = lodash.get(ACCOUNT, 'currency.code')

                    return (
                        <div className="relative">
                            <span
                                className={`bold fsz-16px  ${row.refBalance < 0 ? 'negative-money' : row.refBalance > 0 ? 'positive-money' : ''}`}>
                                {row.refBalance !== null ? Utils.currencyFormatByCurrency(row.refBalance, CURRENCY) : '--'}
                            </span>
                        </div>
                    )
                },
            })
        }

        columns.push(
            {
                title: 'GD đã xử lý',
                key: 'recharge',
                align: 'center',
                render: (row: any) => {
                    return (
                        <>
                            <Checkbox
                                checked={row.recharged}
                                disabled={row.recharged || !SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_RECHARGE)}
                                onChange={() => this.props.handleMarkRechargeTransaction(row)}
                            />
                        </>
                    )
                },
            },
            {
                title: '',
                key: 'log',
                align: 'center',
                width: 48,
                render: (row: any) => {
                    return (
                        <div style={{ width: 35 }}>
                            {' '}
                            {SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_LOG_VIEW) && (
                                <Button
                                    type="link"
                                    className="text-blue font-size-12 underline pd-0"
                                    onClick={() => this.openLogModal(row)}>
                                    <i className="fa-regular fa-file-lines" /> Log
                                </Button>
                            )}
                        </div>
                    )
                },
            },
            {
                title: '',
                key: 'actions',
                width: 32,
                align: 'center',
                fixed: 'right',
                render: (row: TransactionInterface) => {
                    const showMenuRetry =
                        (row.amount > 0 && !row.referenceTx) || (!row.referenceTx && row.amount < 0 && (row.receiptVoucher || row.paymentVoucher))

                    const showMenuEdit = row.type !== 'INITIAL' && !row.referenceTx
                    const isShowCloneButton =
                        (!!row.paymentVoucher && SecurityService.allowTo(ACCOUNT_PERMISSIONS.PAYMENT_VOUCHER_CREATE)) ||
                        (!!row.receiptVoucher && SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_CREATE))

                    const isPendingApprove = row.status !== TransactionStatusEnum.REJECTED
                    const buttonRender: any = []

                    if (isPendingApprove && SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_RETRY_SYNCHRONIZE) && showMenuRetry) {
                        buttonRender.push(() => renderRetry(false, this.handleRetrySynchronize.bind(this, row.code)))
                    }

                    if (isPendingApprove && SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_RETRY_SYNCHRONIZE) && showMenuEdit) {
                        buttonRender.push(() => renderEdit(false, () => this.handleEditTransactionModalVisible(row as any)))
                    }

                    if (isPendingApprove && SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_UPDATE)) {
                        buttonRender.push((isShowTooltip: boolean) => renderEditNote(isShowTooltip, () => this.handleEditTransactionNoteModalVisible(row as any)))
                    }

                    if (isShowCloneButton) {
                        buttonRender.push((isShowTooltip: boolean) => renderClone(isShowTooltip, () => cloneVoucher({
                            paymentVoucher: row.paymentVoucher,
                            receiptVoucher: row.receiptVoucher || ''
                        })))
                    }

                    if (isPendingApprove && !row.hasSubTransaction && SecurityService.allowTo(ACCOUNT_PERMISSIONS.SUB_TRANSACTION_EDIT)) {
                        buttonRender.push((isShowTooltip: boolean) => renderAddSub(isShowTooltip, () => this.handleCreateSubTransactionModalVisible(row as any)))
                    }

                    const isFromVoucher = row.paymentVoucher || row.receiptVoucher
                    if (row.reviewStatus === TransactionReviewStatusEnum.PENDING
                        && isPendingApprove
                        && SecurityService.allowTo(ACCOUNT_PERMISSIONS.TRANSACTION_REJECT)
                        && isFromVoucher
                    ) {
                        buttonRender.push((isShowTooltip: boolean) => renderRejectPayment(isShowTooltip, () => this.handleRejectTransaction(row as any)))
                    }
                    // console.log(navigator)
                    return (
                        <div
                            className="flex-col flex items-center actions"
                            onClick={(e) => e.stopPropagation()}>
                            {buttonRender.length <= 3 &&
                                buttonRender.map((renderAction: any, index: number) => <span
                                    key={`${row.id}_${index}`}>{renderAction()}</span>)}

                            {buttonRender.length > 3 && (
                                <>
                                    <span key={`${row.id}_${0}`}>{buttonRender[0]()}</span>
                                    <span key={`${row.id}_${1}`}>{buttonRender[1]()}</span>
                                    <Popover
                                        arrowContent={false}
                                        overlayClassName="no-padding"
                                        // @ts-ignore
                                        trigger={navigator.userAgentData?.mobile ? 'click' : 'hover'}
                                        content={() => (
                                            <Menu selectable={false}>
                                                {buttonRender.map(
                                                    (renderAction: any, index: number) =>
                                                        index > 1 && <Menu.Item
                                                            key={`${row.id}_${index}`}>{renderAction(false)}</Menu.Item>
                                                )}
                                            </Menu>
                                        )}
                                        placement="leftTop">
                                        <Button className={'blue-btn icon-btn mg-t-8'}>
                                            <i className="fa-solid fa-ellipsis-vertical" />
                                        </Button>
                                    </Popover>
                                </>
                            )}
                        </div>
                    )
                },
            },
        )

        return columns.filter((column: any) => [...keyColumnsToShow, 'log', 'actions'].includes(column.key))
    }

    handleRetrySynchronize = (transactionCode: string) => {
        this.props.onRetrySynchronize(transactionCode)
    }

    handleChangePage = (page: number, pageSize?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    handleEditTransactionModalVisible = (record: TransactionsDomain) => {
        this.props.handleCurrentTransactionEdit(record)
        this.props.handleModalEditTransactionVisibleChange(true)
    }

    handleEditTransactionNoteModalVisible = (record: TransactionsDomain) => {
        this.props.handleCurrentTransactionEdit(record)
        this.props.handleModalEditTransactionNoteVisibleChange(true)
    }

    handleCreateSubTransactionModalVisible = (record: TransactionsDomain) => {
        SubTransaction.getSubTransactions(record.code)
            .then((response) => {
                if (response.data && response.data.length === 0) {
                    this.props.handleCurrentTransactionEdit(record)
                    this.props.handleModalCreateSubTransactionVisibleChange(true)
                } else {
                    NotificationCommon.warning({
                        message: 'Giao dịch đã tồn tại giao dịch con',
                    })
                }
            })
            .catch(() => {
            })
    }

    handleRejectTransaction = (record: TransactionsDomain) => {
        const cancelTransaction = () => {
            const { account } = this.state
            TransactionApi.cancelTransaction(account.code, record.code)
                .then(() => {
                    NotificationCommon.success({ message: "Hủy giao dịch thành công" })
                    this.props.reloadTransaction()
                    Utils.getAppContext(this).func.fetchCurrentAccountTimeout()
                })
                .catch((error: AxiosError<any>) => {
                    let message = error.response?.data?.title && errorMapping[error.response.data.title]
                    NotificationCommon.error({ message: message || "Hủy giao dịch thất bại" })
                })
        }
        ModalCommon.confirm({
            width: 450,
            title: 'Cảnh báo',
            content: <>Bạn chắc chắn muốn hủy giao dịch <span className="font-medium">#{record.code}</span>?</>,
            onOk: cancelTransaction,
        })
    }

    openLogModal = (record: TransactionsDomain) => {
        const { account } = this.state

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.TRANSACTION,
            resourceCode: record.code,
        })
    }

    openDrawerList = (row: TransactionsDomain) => {
        this.setState({
            selectedRow: row,
            showTransactionsByUser: true,
        })
    }

    onCloseDrawerList = () => {
        this.setState({
            selectedRow: undefined,
            showTransactionsByUser: false,
        })
    }

    getPaymentReasons = () => {
        let query = {
            offset: 0,
            limit: 100,
        }
        PaymentReasonApi.filter(query)
            .then((response) => {
                this.setState({ paymentReasons: response.data })
            })
            .catch((err) => console.log(err.response))
    }

    render() {
        const { loading, total, pageSize, items, currentPage } = this.props
        const { showTransactionsByUser, selectedRow, itemHasSubTransaction, focusRows } = this.state
        let expandedListKey: any[] = []
        if (items.length > 0) {
            items.forEach((i: any) => {
                if (this.transactionHasError(i)) {
                    expandedListKey.push(i['code'])
                }
            })
        }
        let rowsExpanded: any = [];

        const handleShowUpSubTransaction = (record: any) => {
            let temp: any = itemHasSubTransaction;
            if (itemHasSubTransaction.find((x: any) => x === record.code)) {
                this.setState({ itemHasSubTransaction: temp })
            } else {
                temp.push(record.code);
                this.setState({ itemHasSubTransaction: temp })
            }
        }

        const handleShowOffSubTransaction = (record: any) => {
            let temp = itemHasSubTransaction;
            if (itemHasSubTransaction.find((x: any,) => x === record.code)) {
                temp.splice(indexOf(temp, record.code), 1);
                this.setState({ itemHasSubTransaction: temp })
            }
        }

        const configExpandableOption: ExpandableConfig<any> = {
            expandIcon: ({ expanded, onExpand, record }) => (
                <>
                    {record.hasSubTransaction && (
                        <div className={'cursor-pointer'}>
                            {(expanded && itemHasSubTransaction.find((x: any) => x === record.code)) ?
                                <span onClick={(e) => {
                                    if (!this.transactionHasError(record)) onExpand(record, e)
                                    handleShowOffSubTransaction(record);
                                }}>
                                    <i className="fa-solid fa-angle-up font-size-12" />
                                </span>
                                : <span onClick={(e) => {
                                    if (!expanded) onExpand(record, e);
                                    handleShowUpSubTransaction(record);
                                }}>
                                    <i className="fa-solid fa-angle-right font-size-12" />
                                </span>}
                        </div>
                    )}
                </>
            ),
            expandedRowRender: (record, index, indent, expanded) => {
                return (<div>
                    {this.renderTransactionError(record)}
                    {record.hasSubTransaction && itemHasSubTransaction.find((x: any) => x === record.code) &&
                        <div style={this.transactionHasError(record) ? { paddingTop: 0 } : undefined}>
                            <SubTransactionViewList
                                record={record}
                                paymentReasons={this.state.paymentReasons}
                                fetchTransactions={this.props.fetchTransactions} />
                        </div>
                    }
                </div>
                )
            },
            onExpandedRowsChange: (expandedKeys) => {
                rowsExpanded = expandedKeys
            },
            expandedRowClassName: () => 'active-row-key',
            // rowExpandable: (record) => record.hasSubTransaction,
            columnWidth: '1%',
            defaultExpandedRowKeys: lodash.uniq(expandedListKey.concat(itemHasSubTransaction)),
            // expandRowByClick: true,
        }

        return (
            <>
                <CommonTable
                    className="table-small"
                    columns={this.getConfigColumns()}
                    dataSource={items}
                    loading={loading}
                    rowKey={(record) => record.code}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 25, 50, 100],
                        onChange: this.handleChangePage,
                        onShowSizeChange: this.handleChangePage,
                        className: total < pageSize ? 'hidden-change-page' : '',
                    }}
                    emptyText="Danh sách lịch sử giao dịch đang trống"
                    tableLayout="auto"
                    expandable={configExpandableOption}
                    onRow={(record, index) => {
                        let isRowExpanded = lodash.includes(itemHasSubTransaction, record.code) || lodash.includes(rowsExpanded, record.code);
                        let focusRow = lodash.includes(focusRows, record.code);
                        let rowHasError = this.transactionHasError(record);
                        return {
                            index, record, isRowExpanded, focusRow, rowHasError,
                            onClick: () => {
                                this.setState({ focusRows: [] });
                            }, // click row
                        }
                    }}
                    components={{ body: { row: TransactionCustomRow } }}
                />

                <LogModal
                    ref={this.logRef}
                    title="Log lịch sử giao dịch"
                    ListItemContent={ListItem}
                    isShowResourceCodeOnTitle={true}
                />

                <TransactionListByUser
                    visible={showTransactionsByUser}
                    onClose={this.onCloseDrawerList}
                    record={selectedRow}
                />

                <ConfirmReviewTransaction
                    ref={this.confirmReviewTransactionRef}
                    handleReviewTransaction={this.props.handleReviewTransaction}
                    isReviewingTransaction={this.props.reviewingTrasaction}
                />
            </>
        )
    }
}

export default DataList
